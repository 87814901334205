table.table tbody > tr.hover:hover{
background-color: lightslategray;
  }

  ul.list-group > li.active1{

    border-left: 8px lightslategray solid; 


  } 

  table.table >tbody>tr>th {
    cursor: pointer;
  }
  table.table >tbody>tr>td:nth-child(4) {
    cursor: pointer;
  }
  table.table >tbody>tr>td:nth-child(4):hover {
    color:yellow;
  }
  table.showBook{
    width:35%;
    margin-left:auto; 
    margin-right:auto;
  }